import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useMain } from "../../../../contexts/main";
import { clientEvent } from "../../../../services/graphql.service";
import {
  getEventGroupsQuery,
  getEventsForPrintQuery,
} from "../../../services/home.service";

export interface EventGroupsResponse {
  administration: string;
  id: string;
  name: string;
  date: Date;
  actual: boolean;
}

export interface EventPrintResponse {
  date: string;
  hour: string;
  local?: string;
  people?: string;
}

export interface EventsByTypePrintResponse {
  type: string;
  order: number;
  events?: EventPrintResponse[];
}

export interface EventGroupPrintResponse {
  administration: string;
  name: string;
  date: Date;
  warning: string;
  types?: EventsByTypePrintResponse[];
}

export const HomeFunctions = () => {
  const { errorMessage, handleChangeBreadcumb, loading, setLoading } = useMain();
  const [printItem, setPrintItem] = useState<EventGroupPrintResponse | null>();
  const [groups, setGroups] = useState<EventGroupsResponse[]>();
  //const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleChangeBreadcumb([
      {
        title: "Calendário",
      },
      {
        title: "Lista de batismos e diversos",
      },
    ]);
  }, []);

  const onCompleted = (response: any) => {
    setPrintItem(response?.data?.printEvents?.data);
  };

  const { loading: loadingEvents, refetch } = useQuery(
    getEventsForPrintQuery(),
    {
      client: clientEvent,
      variables: {
        request: {
          eventGroupId: "",
        },
      },
      fetchPolicy: "no-cache",
      onCompleted: onCompleted,
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            errorMessage(message)
          );
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
      },
    }
  );

  const onCompletedGroup = (response: any) => {
    setGroups(response?.data?.allPublished?.data);
  };

  const { loading: loadingGroup, refetch: refetchGroup } = useQuery(
    getEventGroupsQuery(),
    {
      client: clientEvent,
      variables: {
        request: {
          eventGroupId: "",
        },
      },
      fetchPolicy: "no-cache",
      onCompleted: onCompleted,
      errorPolicy: "all",
      onError: ({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            errorMessage(message)
          );
        if (networkError)
          errorMessage(
            "Ocorreu um erro inesperado. Tente novamente mais tarde!"
          );
      },
    }
  );

  useEffect(() => {
    setLoading(loadingEvents || loadingGroup);
  }, [loadingEvents, loadingGroup]);

  useEffect(() => {
    refetch({
      request: {
        eventGroupId: "",
      },
    }).then((response) => {
      onCompleted(response);
    });

    refetchGroup().then((response) => {
      onCompletedGroup(response);
    });
  }, []);

  return {
    printItem,
    setPrintItem,
    groups,
    refetch,
    onCompleted,
    loading,
  };
};
