import { Flex, Typography } from "antd";
import React from "react";
import styles from "./Loading.module.scss";

interface LoadingProps extends React.HTMLProps<HTMLDivElement> {
  text?: string;
}

export const Loading: React.FC<LoadingProps> = ({ style, text }) => {
  return (
    <div className={styles.content} style={style}>
      <Flex
        vertical
        align="center"
        justify="center"
        className={styles.container}>
        <div className={styles.imageContent}>
          <div className={styles.imageLoading}></div>
          <img className={styles.image} src="/images/logo.svg" />
        </div>
        <Typography.Title
          level={4}
          style={{ color: "#4d4d4d", margin: 0, textAlign: "center" }}>
          {text ?? "Aguarde estamos buscando os dados."}
        </Typography.Title>
      </Flex>
    </div>
  );
};
