import {
  Document,
  Font,
  Page,
  StyleSheet,
  usePDF,
  View,
} from "@react-pdf/renderer";
import { Col, Row, Tabs, Typography } from "antd";
import { useEffect } from "react";
import { Html } from "react-pdf-html";
import ItemsTypes from "../../../../components/reports/table/ItemsTypes";
import { HomeFunctions } from "./Home.functions";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { printPlugin } from "@react-pdf-viewer/print";
import { propertiesPlugin } from "@react-pdf-viewer/properties";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

// Import styles
import "@react-pdf-viewer/full-screen/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import "@react-pdf-viewer/properties/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: "/fonts/Roboto/Roboto-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "/fonts/Roboto/Roboto-Bold.ttf",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Roboto",
    fontWeight: 400,
    padding: "10mm",
    paddingLeft: "7.5mm",
    flexDirection: "column",
  },
  warning: {
    fontSize: "3mm",
  },
});

export const Home = () => {
  const { printItem, setPrintItem, groups, refetch, onCompleted, loading } =
    HomeFunctions();

  const zoomPluginInstance = zoomPlugin({
    enableShortcuts: true,
  });
  const propertiesPluginInstance = propertiesPlugin();
  const printPluginInstance = printPlugin();
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: () => {
      return `Lista de batismos e diversos - ${printItem && printItem.name}`;
    },
  });
  const fullScreenPluginInstance = fullScreenPlugin();

  const plugins = [
    zoomPluginInstance,
    propertiesPluginInstance,
    printPluginInstance,
    getFilePluginInstance,
    fullScreenPluginInstance,
  ];

  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { ShowPropertiesButton } = propertiesPluginInstance;
  const { PrintButton } = printPluginInstance;
  const { DownloadButton } = getFilePluginInstance;
  const { EnterFullScreenButton } = fullScreenPluginInstance;

  const MyDocument = () => (
    <Document
      title={`Lista de batismos e diversos - ${printItem && printItem.name}`}
      subject="Congregação Cristã no Brasil"
      producer="CCB - BH"
      creator="CCB - BH"
      pageMode="fullScreen"
      author="contato@ccbbh.com.br"
      keywords="ccb, lista-de-batismos"
      creationDate={new Date()}
      modificationDate={new Date()}
      language="pt-BR">
      {printItem && (
        <>
          <Page size="A4" style={styles.page}>
            {ItemsTypes({
              print: printItem,
            })}
          </Page>
          <Page size="A4" style={styles.page}>
            <View
              style={{
                fontSize: "10px",
                width: "100%",
                height: "277mm",
              }}
            >
              <Html
                style={{
                  fontSize: "10px",
                  width: "190mm",
                  height: "277mm",
                  gap: "0 10px",
                }}
              >
                {(printItem?.warning ??
                  "Nenhum aviso cadastrado. <br /><br />") +
                  '<img src="/images/Listadigital.png" style="cursor: initial; background-repeat: no-repeat; background-size: contain; background-position: center center;width: 30mm; height: 40mm;"></a>'}
              </Html>
            </View>
          </Page>
        </>
      )}
    </Document>
  );

  const handlerChange = (activeKey: string) => {
    setPrintItem(null);
    refetch({
      request: {
        eventGroupId: activeKey,
      },
    }).then((response) => {
      onCompleted(response);
    });
  };

  const [pdf, setPdf] = usePDF();

  useEffect(() => {
    MyDocument && setPdf(MyDocument());
  }, [printItem]);

  return (
    <Col span={24}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Row gutter={[8, 8]} style={{ overflowY: "auto" }}>
            <Col span={24}>
              {!loading && groups && groups?.length > 0 && (
                <Tabs
                  tabPosition={window.innerWidth < 500 ? "top" : "left"}
                  defaultActiveKey={groups?.find((group) => group.actual)?.id}
                  onChange={handlerChange}
                  items={groups?.map((group) => {
                    const label = `${group.name}${
                      group.actual ? " - Atual" : ""
                    }`;
                    return {
                      key: group.id,
                      label,
                      children: (
                        <Col span={24}>
                          <Row gutter={[16, 16]}>
                            <Col span={24}>
                              <Row gutter={[4, 4]}>
                                <Col span={24}>
                                  <Row gutter={[8, 8]}>
                                    <Typography.Title
                                      level={window.innerWidth < 500 ? 4 : 2}
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                      }}>
                                      Lista de batismos e diversos digital
                                    </Typography.Title>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row gutter={[8, 8]}>
                                    <Typography.Text color="#a1a1a1e0">
                                      Escolha a lista de batismo e diversos para
                                      visualização e download.
                                    </Typography.Text>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                            <Col span={24}>
                              {loading && (
                                <Row gutter={[8, 8]} justify={"center"}>
                                  <Typography.Text
                                    style={{ textAlign: "center" }}>
                                    Carregando as listas da regional Belo
                                    Horizonte...
                                  </Typography.Text>
                                </Row>
                              )}
                            </Col>
                          </Row>
                          <Row justify={"center"}>
                            {!printItem && (
                              <Typography.Text style={{ textAlign: "center" }}>
                                Carregando a lista {label}...
                              </Typography.Text>
                            )}
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                              <div
                                style={{
                                  width: "100%",
                                  height: "calc(100vh - 200px)",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}>
                                <div
                                  className="rpv-core__viewer"
                                  style={{
                                    border: "1px solid rgba(0, 0, 0, 0.3)",
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",
                                  }}>
                                  <div
                                    style={{
                                      alignItems: "center",
                                      backgroundColor: "#eeeeee",
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.1)",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      padding: "4px",
                                    }}>
                                    <div
                                      style={{
                                        alignItems: "center",
                                        backgroundColor: "#eeeeee",
                                        borderBottom:
                                          "1px solid rgba(0, 0, 0, 0.1)",
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "4px",
                                      }}>
                                      <PrintButton />
                                      <DownloadButton />
                                    </div>
                                    <div
                                      style={{
                                        alignItems: "center",
                                        backgroundColor: "#eeeeee",
                                        borderBottom:
                                          "1px solid rgba(0, 0, 0, 0.1)",
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "4px",
                                      }}>
                                      <ZoomOutButton />
                                      <ZoomPopover />
                                      <ZoomInButton />
                                    </div>
                                    <div
                                      style={{
                                        alignItems: "center",
                                        backgroundColor: "#eeeeee",
                                        borderBottom:
                                          "1px solid rgba(0, 0, 0, 0.1)",
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: "4px",
                                      }}>
                                      <EnterFullScreenButton />
                                      <ShowPropertiesButton />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      flex: 1,
                                      overflow: "hidden",
                                    }}>
                                    {pdf.url && (
                                      <Viewer
                                        localization={{
                                          locale: "pt-BR",
                                        }}
                                        fileUrl={pdf.url}
                                        plugins={plugins}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Worker>
                          </Row>
                        </Col>
                      ),
                    };
                  })}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};
