import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { EnvironmentService } from "./envoriment.service";

const getBaseUrl = (service: string) => {
  if (EnvironmentService.isProd)
    return `https://api.ccbbh.com.br/${service}/graphql`;
  if (EnvironmentService.isStg)
    return `https://api-stg.ccbbh.com.br/${service}/graphql`;
  if (EnvironmentService.isDev)
    return `https://api-dev.ccbbh.com.br/${service}/graphql`;
  return "https://localhost:5001/graphql";
};

const errorLink = onError(({ graphQLErrors }) => {
  const notAuthorized =
    graphQLErrors!.findIndex(
      (_) => _.extensions?.code === "AUTH_NOT_AUTHENTICATED"
    ) >= 0;
  if (notAuthorized) {
    window.location.href = `/logout`;
    return;
  }
});

const httpLinkEvent = createHttpLink({
  uri: getBaseUrl("events"),
});

const clientEvent = new ApolloClient({
  link: errorLink.concat(from([httpLinkEvent])),
  cache: new InMemoryCache(),
});

export { clientEvent };

